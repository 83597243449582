import React from "react"

interface PillProps {
  label: string
  onClick: () => void
  theme: string
}

const Pill = ({ label, onClick, theme }: PillProps) => {
  return (
    <button
      className={`pill theme__${theme} typography__display--exception`}
      type="button"
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export default Pill
