import React, { useEffect, useState } from "react"
import Head from "../../components/global/head"
import { graphql } from "gatsby"
import Pill from "../../components/buttons/pill"
import ContentBoundary from "../../components/content/boundary"
import HeroIntroBanner from "../../components/modules/hero_intro_banner"
import StoryNewsListing from "../../components/modules/story_news_listing"
import CtaBanner from "../../components/modules/cta_banner"

const StoriesAndNews = ({ data }) => {
  const { edges } = data.allDatoCmsPost

  const allPosts = edges.slice(0, 12)
  const newsPosts = edges.filter(({ node }) => node.storyListing === false)
  const storyPosts = edges.filter(({ node }) => node.storyListing === true)
  const { ctaBannerModule } = data.allDatoCmsHome.edges[0].node

  const renderedPost = (data) => {
    const excerpt = data.modules.filter(
      ({ __typename }) => __typename === "DatoCmsBodyCopy"
    )

    const thumbnail = data.modules.filter(
      ({ __typename }) => __typename === "DatoCmsHero"
    )

    const handleHeroImage = () => {
      if (thumbnail.length > 0) {
        return thumbnail[0].backgroundImage
      } else {
        return null
      }
    }
    const handleHasImage = () => {
      if (thumbnail.length > 0) {
        return true
      } else {
        return false
      }
    }

    const handleTrimMarkdownBody = () => {
      if (excerpt) {
        const firstPara = excerpt[0].bodyCopyNode.childMarkdownRemark.rawMarkdownBody.split(
          "."
        )
        return firstPara[0] + "."
      } else {
        return ""
      }
    }

    return (
      <StoryNewsListing
        isStoryListing={data.storyListing}
        excerpt={handleTrimMarkdownBody()}
        date={data.meta.publishedAt}
        key={data.id}
        slug={`/stories-and-news/${data.slug}`}
        title={data.title}
        image={handleHeroImage()}
        hasImage={handleHasImage()}
        hasCta={true}
      />
    )
  }

  const renderAllPosts = allPosts.map(({ node }) => {
    return renderedPost(node)
  })

  const renderNewsPosts = newsPosts.map(({ node }) => {
    return renderedPost(node)
  })
  const renderStoryPosts = storyPosts.map(({ node }) => {
    return renderedPost(node)
  })

  const [posts, setPosts] = useState(renderAllPosts)
  const [showPosts, setShowPosts] = useState("all")

  const [newsPillClicked, setNewsPillClicked] = useState(false)
  const [newsPillTheme, setNewsPillTheme] = useState("green")

  const [storyPillClicked, setStoryPillClicked] = useState(false)
  const [storyPillTheme, setStoryPillTheme] = useState("blue")

  const handleNewsPillClick = () => {
    setNewsPillClicked(!newsPillClicked)
    if (storyPillClicked && !newsPillClicked) {
      setStoryPillClicked(false)
    }
  }
  const handleStoryPillClick = () => {
    setStoryPillClicked(!storyPillClicked)

    if (newsPillClicked && !storyPillClicked) {
      setNewsPillClicked(false)
    }
  }

  useEffect(() => {
    if (showPosts === "news") {
      setPosts("news")
      return
    }
    if (showPosts === "stories") {
      setPosts("stories")
      return
    }
    if (showPosts === "all") {
      setPosts("all")
      return
    }
  }, [showPosts])

  useEffect(() => {
    if (newsPillClicked) {
      setNewsPillTheme("dark-green")
      setShowPosts("news")
    }

    if (!newsPillClicked) {
      setNewsPillTheme("green")
      setShowPosts("all")
    }
  }, [newsPillClicked])

  useEffect(() => {
    if (storyPillClicked) {
      setStoryPillTheme("dark-blue")
      setShowPosts("stories")
    }

    if (!storyPillClicked) {
      setStoryPillTheme("blue")
      setShowPosts("all")
    }
  }, [storyPillClicked])

  return (
    <>
      <Head
        title="Stories & News"
        url="https://www.choosetap.com.au/stories-and-news"
        description="Want more Choose Tap? Check out the latest news and stories to find out about what we've been up to across the country."
        keywords="choose tap press release, choose tap media, choose tap new, refillers welcome news, refillers welcome media, refillers welcome press release
        choose tap press release, choose tap media, choose tap new, refillers welcome news, refillers welcome media, refillers welcome press release"
        bodyClassname="body__stories-and-news"
      />
      <section className="pages__child">
        <HeroIntroBanner
          className="newsStoryLanding"
          title="Stories & News"
          shortDescription="Want more Choose Tap? 
            Check out the latest news and stories below."
          sectionName="Stories & News"
          themeColor="green"
          hasImage={false}
        >
          <div className="util__padding-bottom--20">
            <Pill
              theme={newsPillTheme}
              onClick={() => handleNewsPillClick()}
              label="News"
            />

            <Pill
              theme={storyPillTheme}
              onClick={() => handleStoryPillClick()}
              label="Stories"
            />
          </div>
        </HeroIntroBanner>
        <main>
          <ContentBoundary>
            <div className="grids__listing ">
              {showPosts === "all" && renderAllPosts}
              {showPosts === "news" && renderNewsPosts}
              {showPosts === "stories" && renderStoryPosts}
            </div>
          </ContentBoundary>
          {ctaBannerModule.length > 0 && (
            <CtaBanner
              isFooterInstance={true}
              title={ctaBannerModule[0].title}
              ctaLabel={ctaBannerModule[0].ctaLabel}
              ctaLink={ctaBannerModule[0].ctaLink}
              ctaPageUrl={ctaBannerModule[0].ctaPageUrl}
              image={ctaBannerModule[0].backgroundImage}
            />
          )}
        </main>
      </section>
    </>
  )
}

export default StoriesAndNews

export const query = graphql`
  query StoriesAndNewsQuery {
    allDatoCmsPost {
      edges {
        node {
          storyListing
          slug
          id
          title
          modules {
            ... on DatoCmsBodyCopy {
              __typename
              bodyCopyNode {
                childMarkdownRemark {
                  rawMarkdownBody
                  excerpt(pruneLength: 180)
                }
              }
            }
            ... on DatoCmsHero {
              __typename
              backgroundImage {
                url
              }
            }
          }
          meta {
            publishedAt
          }
        }
      }
    }
    allDatoCmsHome {
      edges {
        node {
          ctaBannerModule {
            __typename
            title
            backgroundImage {
              url
              alt
            }
            ctaLink {
              __typename
              ... on DatoCmsPost {
                slug
              }
              __typename
              ... on DatoCmsProgram {
                slug
              }
            }
            ctaLabel
            ctaPageUrl
          }
        }
      }
    }
  }
`
