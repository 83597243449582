import React, { useEffect, useState } from "react"

const IsModernBrowser = () => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (hasMounted) {
    const sUsrAg = window.navigator.userAgent
    if (sUsrAg.indexOf("Trident") > -1) {
      return false
    } else {
      return true
    }
  } else {
    return
  }
}

export default IsModernBrowser
